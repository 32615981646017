import React from "react";
import { Button, Heading, Text, Img } from "..";
import { default as ModalProvider } from "react-modal";
import { formatAmount } from "helper/utils";

export default function DetailsModal({ id, idGroup, isOpen, submitError, totalLoan, marhunValue, outstandingBalance, financingAmt, ProfitAmt, onSubmitButtonClick, onButtonClick, ...props }) {
	const handleButtonClick = () => {
		if (onButtonClick) {
			onButtonClick();
		}
	};
	const handleSubmitButtonClick = () => {
		if (onSubmitButtonClick) {
			onSubmitButtonClick();
		}
	};

	return (
		<ModalProvider { ...props } style={ {
			overlay: {
				backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
			},
			content: {
				outline: "none",
			},
		} }
			appElement={ document.getElementById("root") } isOpen={ isOpen } className="w-[35%]">
			<div className="flex flex-col items-start justify-center w-full px-16 py-10 gap-5 bg-white-A700 rounded-[10px]">
				<Text size="xl" as="p" className="text-center !text-blue_gray-700 !font-bold">Are you sure you want to submit new batch with the following detail ?</Text>
				<div className="grid grid-cols-2 gap-y-5 gap-x-10">
					<div className="flex flex-col items-start justify-start gap-[9px]">
						<Text size="xl" as="p" className="!text-blue_gray-700">
							Total Loan
						</Text>
						<Heading as="h2" className="!text-base">{ totalLoan }</Heading>
					</div>
					<div className="flex flex-col items-start justify-start gap-[9px]">
						<Text size="xl" as="p" className="!text-blue_gray-700">
							Total Marhun Value
						</Text>
						<Heading as="h2" className="!text-base">{ formatAmount(marhunValue) }</Heading>
					</div>
					<div className="flex flex-col items-start justify-start gap-[9px]">
						<Text size="xl" as="p" className="!text-blue_gray-700">
							Total Outstanding Balance
						</Text>
						<Heading as="h2" className="!text-base">{ formatAmount(outstandingBalance) }</Heading>
					</div>
					<div className="flex flex-col items-start justify-start gap-[9px]">
						<Text size="xl" as="p" className="!text-blue_gray-700">
							Total Financing Amount
						</Text>
						<Heading as="h2" className="!text-base">{ formatAmount(financingAmt) }</Heading>
					</div>
					<div className="flex flex-col items-start justify-start gap-[9px]">
						<Text size="xl" as="p" className="!text-blue_gray-700">
							Total Profit Amount
						</Text>
						<Heading as="h2" className="!text-base">{ formatAmount(ProfitAmt) }</Heading>
					</div>
				</div>
				{ submitError &&
					<Text as="p" className="!text-red-A700 ">
						{ submitError }
					</Text>
				}
				<div className="flex justify-between w-full mt-3">
					<Button onClick={ handleSubmitButtonClick } color="blue_900" size="md" className="uppercase !text-xs font-bold w-fit !rounded-xl">
						Submit
					</Button>
					<Button onClick={ handleButtonClick } color="gray_100_01" size="md" className="uppercase !text-xs font-bold w-fit !rounded-xl">
						Cancel
					</Button>
				</div>

			</div>
		</ModalProvider >
	);
}
