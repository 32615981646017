import React from "react";
import { Button, Text } from "..";
import { default as ModalProvider } from "react-modal";

export default function ModalOnBoard({ isOpen, onClose, item }) {
  if (!item) return null;

  return (
    <ModalProvider
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: isOpen ? "#D9D9D9CC" : "transparent",
        },
        content: {
          outline: "none",
        },
      }}
      appElement={document.getElementById("root")}
      className="w-[40%]" // Mirip pendekatan di DetailsModal
    >
      {/* Konten modal mirip DetailsModal */}
      <div className="flex flex-col items-start justify-center w-full px-16 py-10 gap-5 bg-white-A700 rounded-[10px]">
        <Text
          size="2xl"
          as="p"
          className="text-center !text-blue_gray-700 !font-bold w-full"
        >
          On Board Detail
        </Text>
        <div className="flex flex-col gap-2 w-full">
          <Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
            ID: {item.id}
          </Text>
          <Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
            Name: {item.name}
          </Text>
          <Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
            Email: {item.email}
          </Text>
          <Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
            On Board Date: {item.onBoardDate}
          </Text>
          <Text size="xl" as="p" className="!text-blue_gray-700 !font-semibold">
            Status: {item.status}
          </Text>
        </div>
        <div className="w-full flex justify-end">
          <Button
            onClick={onClose}
            color="gray_100_01"
            size="md"
            className="uppercase !text-xs font-bold !rounded-xl mt-3"
          >
            Close
          </Button>
        </div>
      </div>
    </ModalProvider>
  );
}
