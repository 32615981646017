import React, { useState } from "react";
import {
  Img,
  Heading,
  Text,
  Header,
  DefaultSideBar,
  Input,
  Button,
  TextArea,
} from "components";
import { useNavigate } from "react-router-dom";
import * as loader from "components/Loading/loading"; // jika ingin loader

export default function CreateOnBoard() {
  const navigate = useNavigate();
  // State
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [errors, setErrors] = useState({});

  // Handlers
  const handleNameChange = (val) => setName(val);
  const handleEmailChange = (val) => setEmail(val);
  const handlePhoneChange = (val) => setPhone(val);
  const handleAddressChange = (val) => setAddress(val);
  const handleRegistrationNumberChange = (val) => setRegistrationNumber(val);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setUploadedFileName(file.name);
    }
  };

  // Validasi
  const validateForm = () => {
    let newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!phone) newErrors.phone = "Phone is required";
    if (!address) newErrors.address = "Address is required";
    if (!registrationNumber)
      newErrors.registrationNumber = "Registration Number is required";
    if (!uploadedFile) newErrors.uploadedFile = "Registration file is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Submit
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      loader.showLoader(); // jika mau pakai loader
      setTimeout(() => {
        loader.hideLoader();
      }, 1000);
      navigate("/on-board");
    }
  };

  return (
    <div className="w-full relative bg-white-A700 pl-[81px] pt-[10vh]">
      <Header />
      <DefaultSideBar />

      <div className="flex flex-row justify-start p-[27px]">
        <div className="flex flex-col justify-center w-full ml-2 p-[43px] border-blue_gray-100_01 border border-solid bg-white-A700 rounded-[10px]">
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full"
          >
            {/* Kolom 1 */}
            <div className="flex flex-col gap-4">
              {/* Name */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Name
                </Text>
                <Input
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  placeholder="Enter your name"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {errors.name && (
                  <Text as="p" className="text-red-500">
                    {errors.name}
                  </Text>
                )}
              </div>

              {/* Email */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Email
                </Text>
                <Input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {errors.email && (
                  <Text as="p" className="text-red-500">
                    {errors.email}
                  </Text>
                )}
              </div>
            </div>

            {/* Kolom 2 */}
            <div className="flex flex-col gap-4">
              {/* Phone */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Phone
                </Text>
                <Input
                  type="tel"
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter your phone number"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {errors.phone && (
                  <Text as="p" className="text-red-500">
                    {errors.phone}
                  </Text>
                )}
              </div>

              {/* Address */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Address
                </Text>
                <TextArea
                  value={address}
                  onChange={handleAddressChange}
                  placeholder="Enter your address"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {errors.address && (
                  <Text as="p" className="text-red-500">
                    {errors.address}
                  </Text>
                )}
              </div>
            </div>

            {/* Kolom 3 */}
            <div className="flex flex-col gap-4">
              {/* Registration Number */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Registration Number
                </Text>
                <Input
                  type="text"
                  value={registrationNumber}
                  onChange={handleRegistrationNumberChange}
                  placeholder="Enter registration number"
                  className="w-full border-blue_gray-100_02 border border-solid"
                />
                {errors.registrationNumber && (
                  <Text as="p" className="text-red-500">
                    {errors.registrationNumber}
                  </Text>
                )}
              </div>

              {/* Upload Registration */}
              <div>
                <Text as="p" className="!text-blue_gray-700 mb-1">
                  Upload Registration Number
                </Text>
                <div
                  onClick={() => document.getElementById("fileInput").click()}
                  className="flex flex-row items-center w-full gap-4 p-4 border-blue_gray-300_01 border border-dashed rounded-[10px] cursor-pointer"
                >
                  <div className="flex flex-col gap-1 w-full">
                    {uploadedFile ? (
                      <Text
                        as="p"
                        className="!text-blue_gray-900_04 !font-poppins"
                      >
                        {uploadedFileName}
                      </Text>
                    ) : (
                      <Text
                        as="p"
                        className="!text-blue_gray-900_04 !font-poppins"
                      >
                        Click to upload or drag and drop
                      </Text>
                    )}
                    <Text
                      size="s"
                      as="p"
                      className="!text-blue_gray-300_01 !font-poppins"
                    >
                      Accepts .pdf, .jpg, .png
                    </Text>
                  </div>
                </div>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  accept=".pdf,.jpg,.png"
                  onChange={handleFileUpload}
                />
                {errors.uploadedFile && (
                  <Text as="p" className="text-red-500">
                    {errors.uploadedFile}
                  </Text>
                )}
              </div>
            </div>

            {/* Submit Button (Full Width) */}
            <div className="flex justify-center w-full">
              <Button
                type="submit"
                size="md"
                color="indigo_900"
                className="mt-[50px] tracking-[0.40px] font-poppins uppercase font-medium w-[183px]"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
