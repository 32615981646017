import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar } from "components";
import TablePool from "components/TablePool";
import { batch } from "services/batch";

export default function PoolPage() {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const batchData = await batch();
      setData(batchData.data);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
      <Header />
      <DefaultSideBar />
      {data ? (
        <div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
          <TablePool data={data.batchings} />
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
