import React from "react";
import PropTypes from "prop-types";
import { Menu, Sidebar } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Img } from "components";
import { logout } from "constant/localStorage";

export const DefaultSideBar = React.forwardRef((className = "", ...props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const MenuItemLink = ({ to, className, children, label }) => (
    <Link to={to} className="flex justify-center items-center">
      <div
        className={`flex flex-col justify-center items-center w-16 h-16 ${className}`}
      >
        <div
          className={`flex justify-center items-center w-12 h-12 ${
            location.pathname === to && "bg-white-A700 rounded-[10px]"
          }`}
        >
          {children}
        </div>
        <div className=" text-white-A700 text-xs">{label}</div>
      </div>
    </Link>
  );

  const handleItemClick = () => {
    logout();
    navigate("/login");
  };

  return (
    <Sidebar
      {...props}
      width="79px"
      className={`${className} w-[81px] h-full !fixed top-0 left-0 bg-indigo-900 shadow-sm z-10 mt-[6vh]`}
    >
      <Menu
        menuItemStyles={{
          button: {
            padding: 0,
            height: "48px !important",
            borderRadius: "10px",
            "&:hover, &.ps-active": { backgroundColor: "#ffffff !important" },
          },
        }}
        className="flex flex-col items-center justify-start w-full mt-6"
      >
        <MenuItemLink to="/" className="mt-0" label="Dashboard">
          <Img
            src="images/img_uim_grids.svg"
            alt="uimgrids_one"
            className="h-[24px] w-[24px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/originator" className="mt-4" label="Originator">
          <Img
            src="images/img_majesticons_users.svg"
            alt="majesticons_one"
            className="h-[30px] w-[30px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/fund-manager" className="mt-4" label="FManager">
          <Img
            src="images/img_majesticons_users_two.svg"
            alt="majesticons_two"
            className="h-[30px] w-[30px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/report" className="mt-4" label="Assets">
          <Img
            src="images/img_icon_park_table_report.svg"
            alt="iconparktable"
            className="h-[30px] w-[30px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/batch" className="mt-4" label="Batch">
          <Img
            src="images/img_material_symbol_orange_300.svg"
            alt="materialsymbol"
            className="h-[24px] w-[24px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/pool" className="mt-4" label="Pool">
          <Img
            src="images/img_material_pool.svg"
            alt="materialsymbol"
            className="h-[24px] w-[24px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/redeem" className="mt-4" label="Redeem">
          <Img
            src="images/img_redeem.svg"
            alt="materialsymbol"
            className="h-[24px] w-[24px]"
          />
        </MenuItemLink>
        <MenuItemLink to="/on-board" className="mt-4" label="On Board">
          <Img
            src="images/img_icon_park_table_on_board.svg"
            alt="onboard"
            className="h-[30px] w-[30px]"
          />
        </MenuItemLink>
        <div className="h-px px-5 opacity-75 bg-yellow-800_87 mt-4" />
        <div className="flex justify-center items-center">
          <div
            onClick={handleItemClick}
            className="flex flex-col justify-center items-center w-16 h-16 mt-4"
          >
            <div className="flex justify-center items-center w-12 h-12">
              <Img
                src="images/img_power-off.png"
                alt="gridiconsuser"
                className="h-[30px] w-[30px]"
              />
            </div>
            <div className=" text-white-A700 text-xs">Logout</div>
          </div>
        </div>
      </Menu>
    </Sidebar>
  );
});

DefaultSideBar.propTypes = {
  className: PropTypes.string,
};
