import React, { useState } from "react";
import { Img, SelectBox, Input, Heading, Button, Text } from "..";
import { CloseSVG } from "../../assets/images";
import * as XLSX from "xlsx";
import DetailsModal from "components/ModalOnBoard";

const localStatusOptions = [
  { label: "All Status", value: "" },
  { label: "Active", value: "Active" },
  { label: "Suspended", value: "Suspended" },
];

function OnBoardRow({ item }) {
  const [status, setStatus] = useState(item.status);
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const handleSuspendToggle = () => {
    if (status === "Active") setStatus("Suspended");
    else if (status === "Suspended") setStatus("Active");
  };

  const handleDownloadExcel = () => {
    const dataForExcel = [
      {
        ID: item.id,
        Name: item.name,
        Email: item.email,
        "On Board Date": item.onBoardDate,
        Status: status,
      },
    ];

    const ws = XLSX.utils.json_to_sheet(dataForExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "OnBoardInfo");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `onboard_data_${item.id}.xlsx`;
    anchor.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <>
      <div className="flex items-center py-2 w-full hover:bg-gray-50_02">
        <div className="w-[10%]">{item.id}</div>
        <div className="w-[20%]">{item.name}</div>
        <div className="w-[25%]">{item.email}</div>
        <div className="w-[15%]">{item.onBoardDate}</div>
        <div className="w-[10%]">{status}</div>
        <div className="w-[20%] flex justify-start items-center gap-3 pl-5">
          <Img
            src="images/img_carbon_view.svg"
            alt="view-detail"
            className="h-6 w-6 cursor-pointer"
            onClick={() => setIsOpenDetail(true)}
          />
          <Img
            src="images/img_material_pdf.svg"
            alt="download"
            className="h-6 w-6 cursor-pointer"
            onClick={() => {}}
          />
          <Button
            size="xs"
            className="font-bold w-fit"
            onClick={handleSuspendToggle}
          >
            {status === "Active" ? "Suspend" : "Unsuspend"}
          </Button>
        </div>
      </div>
      {/* Garis pemisah */}
      <div className="h-px bg-orange-100 w-full"></div>

      {/* Modal Detail */}
      <DetailsModal
        isOpen={isOpenDetail}
        onClose={() => setIsOpenDetail(false)}
        item={{ ...item, status }}
      />
    </>
  );
}

export default function TableOnBoard({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(localStatusOptions[0]);

  const filteredData = data.filter((item) => {
    const lowerName = item.name.toLowerCase();
    const lowerEmail = item.email.toLowerCase();
    const query = searchTerm.toLowerCase();

    const matchSearch = lowerName.includes(query) || lowerEmail.includes(query);

    const matchStatus =
      !selectedStatus.value || item.status === selectedStatus.value;

    return matchSearch && matchStatus;
  });

  const sortedData = filteredData.sort(
    (a, b) => new Date(b.onBoardDate) - new Date(a.onBoardDate)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleSearch = (val) => {
    setSearchTerm(val);
    setCurrentPage(1);
  };

  const handleSelectStatus = (option) => {
    setSelectedStatus(option);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full gap-5">
      {/* Bagian Filter */}
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row items-center gap-[19px]">
          <Heading
            size="xl"
            as="h1"
            className="!text-gray-700 text-center !font-bold"
          >
            Apply Filters :
          </Heading>
          <SelectBox
            name="status"
            placeholder="All Status"
            options={localStatusOptions}
            value={selectedStatus}
            onChange={handleSelectStatus}
            indicator={
              <Img
                src="images/img_arrowdown_blue_gray_900_02.svg"
                alt="arrow_down"
              />
            }
            className="w-[160px] gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid rounded-[5px]"
          />
        </div>

        <div className="flex flex-row justify-start items-center gap-[15px]">
          <Input
            name="search"
            value={searchTerm}
            placeholder="Search Name or Email"
            onChange={handleSearch}
            suffix={
              searchTerm?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchTerm("")}
                  height={27}
                  width={31}
                  fillColor="#7e7e7eff"
                />
              ) : (
                <Img
                  src="images/img_search.svg"
                  alt="search"
                  className="cursor-pointer"
                />
              )
            }
            className="w-[267px] gap-[35px] text-gray-700 border-gray-300 border-2 border-dashed"
          />
        </div>
      </div>

      {/* Container Table */}
      <div className="w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-5">
        {/* Header Table */}
        <div className="flex items-center w-full pb-3 font-bold !text-blue_gray-200">
          <div className="w-[10%]">ID</div>
          <div className="w-[20%]">Name</div>
          <div className="w-[25%]">Email</div>
          <div className="w-[15%]">On Board Date</div>
          <div className="w-[10%]">Status</div>
          <div className="w-[20%] text-center">Action</div>
        </div>
        <div className="h-px bg-orange-100 w-full"></div>

        {/* Rows */}
        <div className="flex flex-col w-full">
          {currentItems.map((item, index) => (
            <OnBoardRow key={index} item={item} />
          ))}
        </div>

        {/* Pagination */}
        <div className="flex flex-row justify-end items-center w-full mt-5">
          <div className="flex gap-3">
            <div
              onClick={() => paginate(currentPage - 1)}
              className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs cursor-pointer"
            >
              {"<"}
            </div>
            {Array.from({ length: totalPages }, (_, i) => (
              <div
                key={i}
                onClick={() => paginate(i + 1)}
                className={`rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs cursor-pointer ${
                  currentPage === i + 1
                    ? "bg-yellow-800 text-white"
                    : "bg-gray-100_01 text-black-900"
                }`}
              >
                {i + 1}
              </div>
            ))}
            <div
              onClick={() => paginate(currentPage + 1)}
              className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs cursor-pointer"
            >
              {">"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
