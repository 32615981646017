import React, { useState } from "react";
import { Img, SelectBox, Input, Heading, Text, Button } from "..";
import { CloseSVG } from "../../assets/images";
import { CheckBox } from "components/CheckBox";
import { useNavigate } from "react-router";
import { statusBatchOptions } from "constant/options";
import { deleteBatch, detailBatch } from "services/batch";
import DefaultModal from "components/DefaultModal";
import * as XLSX from "xlsx";
import * as loader from "../../components/Loading/loading";
import DetailsModal from "components/ModalDetail";

const TableRow = ({
  checkbox,
  company,
  status,
  noBatch,
  dateStart,
  submitDate,
  activeDate,
  activeUntil,
  reportingData,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleToDetail = () => {
    navigate(`/edit-batch?id=${noBatch}`);
  };

  const handleQuickDetail = async () => {
    try {
      const data = await detailBatch(noBatch);
      let totalLoan = data.data.batching_data.total_loan_active;
      let marhunValue = data.data.batching_data.total_marhun_active;
      let outstandingBalance = data.data.batching_data.total_outstanding_active;
      let financingAmt = data.data.batching_data.total_financing_active;
      let profitAmt = data.data.batching_data.total_profit_active;
      setIsOpenDetail(true);
      setModalData({
        totalLoan,
        marhunValue,
        outstandingBalance,
        financingAmt,
        profitAmt,
      });
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const handleDeleteBatch = async () => {
    loader.showLoader();
    try {
      const successDelete = await deleteBatch(noBatch);
      setIsOpen(false);
      if (successDelete) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    } finally {
      loader.hideLoader();
    }
  };

  const handleDownloadExcel = async () => {
    loader.showLoader();
    try {
      const reportsData = await fetchData(noBatch);
      const excelData = reportsData;
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `report_data.xlsx`;
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    } finally {
      loader.hideLoader();
    }
  };

  const fetchData = async (id) => {
    try {
      const batchData = await detailBatch(id);
      const batchDataArray = [];
      for (
        let rowIdx = 0;
        rowIdx < batchData.data.batching_data.batching_data.length;
        rowIdx++
      ) {
        const batchingData =
          batchData.data.batching_data.batching_data[rowIdx].reporting_data;
        if (batchingData && Object.keys(batchingData).length !== 0) {
          batchDataArray.push(batchingData);
        }
      }
      batchDataArray.sort((a, b) => {
        const keyToSortBy = "serial_no";
        return a[keyToSortBy] - b[keyToSortBy];
      });
      return batchDataArray;
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };

  const getStatus = () => {
    const isDateEmpty = (date) => date === "0001-01-01T00:00:00Z";

    if (
      !isDateEmpty(dateStart) &&
      isDateEmpty(submitDate) &&
      isDateEmpty(activeDate) &&
      isDateEmpty(activeUntil)
    ) {
      return "Ready";
    } else if (
      !isDateEmpty(dateStart) &&
      !isDateEmpty(submitDate) &&
      isDateEmpty(activeDate) &&
      isDateEmpty(activeUntil)
    ) {
      return "Pending";
    } else if (
      !isDateEmpty(dateStart) &&
      !isDateEmpty(submitDate) &&
      !isDateEmpty(activeDate) &&
      !isDateEmpty(activeUntil)
    ) {
      return "Active";
    } else {
      return "Unknown";
    }
  };

  const handleCreatePool = () => {
    navigate(`/create-pool?id=REPORTGROUP-98e2f00e3c0e49d1b8ec916d3902fb67`);
  };

  return (
    <div className="flex items-center justify-center w-full hover:bg-gray-50_02">
      <CheckBox className="w-[7%]" value={checkbox} />
      <Text
        as="p"
        className="!text-blue_gray-900_06 tracking-[-0.14px] w-[40%]"
      >
        {noBatch}
      </Text>
      <Text
        as="p"
        className="!text-blue_gray-900_06 tracking-[-0.14px] w-[28%] col-span-2"
      >
        {company}
      </Text>
      <Text
        as="p"
        className={`!font-semibold !text-blue_gray-900_06 tracking-[-0.14px] w-[15%] ${
          getStatus() === "Active"
            ? "!text-teal-700"
            : getStatus() === "Ready"
            ? "!text-blue_gray-600"
            : "!text-yellow-800_01"
        }`}
      >
        {getStatus()}
      </Text>
      <div
        onClick={handleCreatePool}
        className="flex justify-center items-center w-[10%]"
      >
        <Button color="yellow_800" size="xs" className="font-bold w-fit">
          Create
        </Button>
      </div>
      <div className="flex justify-center items-center w-[10%]">
        <Img
          onClick={handleQuickDetail}
          src="images/img_carbon_view.svg"
          alt="materialsymbol"
          className="h-[24px] w-[24px] ml-2.5 my-[17px]"
        />
        <Img
          onClick={handleToDetail}
          src="images/img_edit.svg"
          alt="vector_one"
          className="h-[18px] w-[18px] ml-2.5 my-[17px]"
        />
        <Img
          onClick={() => setIsOpen(true)}
          src="images/img_material_symbol_red_a700.svg"
          alt="materialsymbol"
          className="h-[24px] w-[24px] ml-2.5"
        />
      </div>
      <DefaultModal
        isOpen={isOpen}
        type="attention"
        title="Attention"
        desc="Are you sure you want to delete the batch?"
        onNoButtonClick={() => setIsOpen(false)}
        onYesButtonClick={handleDeleteBatch}
      />

      <DetailsModal
        isOpen={isOpenDetail}
        title="Detail Batch"
        onButtonClick={() => setIsOpenDetail(false)}
        id={noBatch}
        totalLoan={modalData ? modalData.totalLoan : 0}
        marhunValue={modalData ? modalData.marhunValue : 0}
        outstandingBalance={modalData ? modalData.outstandingBalance : 0}
        financingAmt={modalData ? modalData.financingAmt : 0}
        ProfitAmt={modalData ? modalData.profitAmt : 0}
      />
    </div>
  );
};

const TableBatch = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const handleSearch = (e) => {
    setSearchTerm(e);
    setCurrentPage(1);
  };

  const [checkedItems, setCheckedItems] = useState(
    Array.isArray(data) ? Array(data.length).fill(false) : []
  );
  const [selectAll, setSelectAll] = useState(false);
  const handleItemClick = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newCheckedItems = newSelectAll
      ? Array(data.length).fill(true)
      : Array(data.length).fill(false);
    setCheckedItems(newCheckedItems);
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = data
    ? data.filter(
        (item) =>
          item.id.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (!selectedStatus || item.status === selectedStatus.value)
      )
    : [];
  const sortedData = filteredData.slice().sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col items-center justify-start w-full gap-5">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row justify-start items-center gap-[19px]">
          <Heading
            size="xl"
            as="h1"
            className="!text-gray-700 text-center !font-bold"
          >
            Apply Filters :
          </Heading>
          <SelectBox
            indicator={
              <Img
                src="images/img_arrowdown_blue_gray_900_02.svg"
                alt="arrow_down"
              />
            }
            name="status"
            placeholder="Status"
            options={statusBatchOptions}
            onChange={(value) => setSelectedStatus(value)}
            className="w-[160px] gap-px !text-blue_gray-100_02 font-bold border-gray-300 border-2 border-solid !rounded-[5px]"
          />
        </div>

        <div className="flex flex-row justify-start items-center gap-[15px]">
          <Input
            name="search"
            placeholder="Search Name"
            onChange={handleSearch}
            suffix={
              searchTerm?.length > 0 ? (
                <CloseSVG
                  onClick={() => setSearchTerm("")}
                  height={27}
                  width={31}
                  fillColor="#7e7e7eff"
                />
              ) : (
                <Img
                  src="images/img_search.svg"
                  alt="search"
                  className="cursor-pointer"
                />
              )
            }
            className="w-[267px] gap-[35px] text-gray-700 border-gray-300 border-2 border-dashed"
          />
          {/* <Button color="yellow_800" size="sm" className="font-bold min-w-[126px]">
						Filter
					</Button> */}
        </div>
      </div>

      <div className="w-full bg-white-A700 shadow-2xl rounded-[10px] p-5 flex flex-col gap-10">
        {/* Table */}
        <div className="flex flex-col  w-full ">
          <div className="flex items-center justify-center w-full pb-5">
            <CheckBox
              className="w-[7%]"
              onChange={handleSelectAll}
              value={selectAll}
            />
            <Text
              as="p"
              className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[40%]"
            >
              Batch No
            </Text>
            <Text
              as="p"
              className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[28%] col-span-2"
            >
              Company Name
            </Text>
            <Text
              as="p"
              className="!font-bold !text-blue_gray-200 tracking-[-0.14px] w-[15%]"
            >
              Status
            </Text>
            <Text
              as="p"
              className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[10%]"
            >
              Create Pool
            </Text>
            <Text
              as="p"
              className="!font-bold !text-blue_gray-200 tracking-[-0.14px] text-center w-[10%]"
            >
              Action
            </Text>
          </div>
          <div className="h-px bg-orange-100 w-full"></div>
          <div className="flex flex-col items-center justify-start w-full">
            {currentItems.map((item, index) => (
              <div
                key={index}
                className="w-full"
                onClick={() => handleItemClick(index)}
              >
                <TableRow
                  noBatch={item.id}
                  checkbox={checkedItems[index]}
                  company={item.company_name}
                  status={
                    item.status === "non-active" ? "expired" : item.status
                  }
                  dateStart={item.created_at}
                  submitDate={item.submit_date}
                  activeDate={item.active_date}
                  activeUntil={item.active_until}
                  reportingData={item.batching_data.map(
                    (data) => data.reporting_data
                  )}
                />
                <div className="h-px bg-orange-100 w-full"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="flex flex-row justify-end items-center w-full">
          <div className="flex gap-3">
            <div
              onClick={
                currentPage > 1 ? () => paginate(currentPage - 1) : undefined
              }
              className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
            >
              {`<`}
            </div>
            {Array.from(
              { length: Math.ceil(filteredData.length / itemsPerPage) },
              (_, i) => (
                <div
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs ${
                    currentPage === i + 1
                      ? "bg-yellow-800 text-white-A700"
                      : "bg-gray-100_01 text-black-900"
                  } `}
                >
                  {i + 1}
                </div>
              )
            )}
            <div
              onClick={
                currentPage < Math.ceil(filteredData.length / itemsPerPage)
                  ? () => paginate(currentPage + 1)
                  : undefined
              }
              className="bg-gray-100_01 rounded-md font-medium border-gray-200 border border-solid h-6 w-6 flex justify-center items-center text-xs"
            >
              {">"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableBatch;
