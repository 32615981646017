import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar } from "components";
import TableFundManagers from "components/TableFundManagers";
import { originator } from "services/originator";

export default function FundManagerPage() {
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const originatorData = await originator();
      setData(originatorData.data);
    } catch (error) {
      console.error("Error fetching originator data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className=" w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
      <Header />
      <DefaultSideBar />
      {data ? (
        <div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
          <TableFundManagers data={data.listofregistrationdata} />
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
