import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Originator from "pages/Originator";
import DetailOriginator from "pages/DetailOriginator";
import Dashboard from "pages/Dashboard";
import Report from "pages/Report";
import DetailReport from "pages/DetailReport";
import BatchPage from "pages/Batch";
import LoginPage from "pages/Login";
import CreateBatch from "pages/CreateBatch";
import EditBatch from "pages/EditBatch";
import UpdateBatch from "pages/UpdateBatch";
import RedeemPage from "pages/Redeem";
import DetailRedeem from "pages/DetailRedeem";
import ResumeRedeemPage from "pages/ResumeRedeem";
import FundManagerPage from "pages/FundManager";
import DetailFundManager from "pages/DetailFundManager";
import OnBoardPage from "pages/OnBoard";
import CreateOnBoard from "pages/CreateOnBoard";
import PoolPage from "pages/Pool";
import CreatePool from "pages/CreatePool";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Dashboard /> },

    { path: "*", element: <NotFound /> },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "originator",
      element: <Originator />,
    },
    {
      path: "originator-detail",
      element: <DetailOriginator />,
    },
    {
      path: "report",
      element: <Report />,
    },
    {
      path: "report-detail",
      element: <DetailReport />,
    },
    {
      path: "batch",
      element: <BatchPage />,
    },
    {
      path: "create-batch",
      element: <CreateBatch />,
    },
    {
      path: "edit-batch",
      element: <EditBatch />,
    },
    {
      path: "update-batch",
      element: <UpdateBatch />,
    },
    {
      path: "redeem",
      element: <RedeemPage />,
    },
    {
      path: "detail-redeem",
      element: <DetailRedeem />,
    },
    {
      path: "resume-redeem",
      element: <ResumeRedeemPage />,
    },
    {
      path: "fund-manager",
      element: <FundManagerPage />,
    },
    {
      path: "fund-manager-detail",
      element: <DetailFundManager />,
    },
    {
      path: "on-board",
      element: <OnBoardPage />,
    },
    {
      path: "create-on-board",
      element: <CreateOnBoard />,
    },
    {
      path: "pool",
      element: <PoolPage />,
    },
    {
      path: "create-pool",
      element: <CreatePool />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
