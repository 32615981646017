import React, { useEffect, useState } from "react";
import { Header, DefaultSideBar, Button } from "components";
import TableOnBoard from "components/TableOnBoard";
import { useNavigate } from "react-router-dom";

const dummyOnBoardData = [
  {
    id: "001",
    name: "Alpha Corp",
    email: "info@alpha.com",
    onBoardDate: "2023-10-01",
    status: "Active",
  },
  {
    id: "002",
    name: "Beta Holdings",
    email: "contact@beta.com",
    onBoardDate: "2023-10-05",
    status: "Suspended",
  },
  {
    id: "003",
    name: "Gamma Group",
    email: "hello@gamma.co.id",
    onBoardDate: "2023-11-02",
    status: "Active",
  },
  {
    id: "004",
    name: "Delta Solutions",
    email: "admin@delta.org",
    onBoardDate: "2023-12-15",
    status: "Active",
  },
  {
    id: "005",
    name: "Omega Industries",
    email: "info@omega.io",
    onBoardDate: "2024-01-05",
    status: "Suspended",
  },
];

// Halaman OnBoardPage
export default function OnBoardPage() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  // Simulasi fetch data dummy
  const fetchData = async () => {
    setTimeout(() => {
      setData(dummyOnBoardData);
    }, 500);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const createOnBoard = () => {
    navigate("/create-on-board");
    return;
  };
  return (
    <div className="w-full relative bg-gray-100 pl-[81px] pt-[10vh]">
      <Header />
      <DefaultSideBar />
      {data ? (
        <div className="flex flex-col items-start justify-start w-full mt-5 gap-[17px] px-10">
          {/* Tombol Create On Board */}
          <div className="flex justify-end w-full">
            <Button
              color="yellow_800"
              size="md"
              className="font-bold min-w-[150px]"
              onClick={() => createOnBoard()}
            >
              Create On Board
            </Button>
          </div>

          {/* Tabel OnBoard */}
          <TableOnBoard data={data} />
        </div>
      ) : (
        <div className="flex w-full h-[90vh] justify-center items-center">
          Loading...
        </div>
      )}
    </div>
  );
}
